.cont {
    position: fixed;
    z-index: 9999;
    /* position: fixed; */
    gap: 4%;
    height: 150px;
}

nav {
    position: relative;
    backdrop-filter: blur(4px);
    --d: 15px;
    --b: 4px;
    --c: orange;
    --gc: var(--c) calc(50% + var(--b)*0.707), #0000 0;
    --gl: var(--c), #0000 40% 60%, var(--c);

    clip-path: polygon(var(--d) 0, calc(100% - var(--d)) 0, 100% var(--d), 100% calc(100% - var(--d)), calc(100% - var(--d)) 100%, var(--d) 100%, 0 calc(100% - var(--d)), 0 var(--d));
    height: 64px;
    width: 937px;
}

.backgroundOverlay {

    width: 937px;
    height: 64px;
    left: 0;
    top: 0;
    position: absolute;
    background: rgba(38, 39, 41, 0.80);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 9px;


}

.container {
    position: relative;
}

.navbarContent {
    left: 24px;
    top: 19px;

    gap: 24px;
}


.navLink {

    font-size: 20px;
    font-family: 'Quicksand', sans-serif;
    font-weight: 500;
    cursor: pointer;
}

.navDot {
    width: 8px;
    height: 8px;
    background: #DDB96E;
    border-radius: 9999px;
}

.navText {
    color: #C3C3C3;
    font-weight: 400;
}

.buttonContainer {
    display: flex !important;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    gap: 1rem;
}

.buttonTablet {
    background-color: #DDB96E;
    color: #262729;
    text-align: center;
    font-weight: bold;

    font-family: Quicksand;
    line-height: auto;
    border-style: hidden;
    outline: none;

    --d: 9px !important;
    --b: 4px !important;
    --c: orange !important;
    --gc: var(--c) calc(50% + var(--b) * 0.707), #0000 0 !important;
    --gl: var(--c), #0000 40% 60%, var(--c) !important;
    clip-path: polygon(var(--d) 0, calc(100% - var(--d)) 0, 100% var(--d), 100% calc(100% - var(--d)), calc(100% - var(--d)) 100%, var(--d) 100%, 0 calc(100% - var(--d)), 0 var(--d));
    border-radius: 12px;

}

.button {
    background-color: #DDB96E;
    color: #262729;
    text-align: center;
    font-weight: bold;
    font-size: 21px;
    font-family: Quicksand;
    line-height: auto;
    border-style: hidden;
    outline: none;
    width: 152px;
    height: 100%;
    --d: 9px !important;
    --b: 4px !important;
    --c: orange !important;
    --gc: var(--c) calc(50% + var(--b) * 0.707), #0000 0 !important;
    --gl: var(--c), #0000 40% 60%, var(--c) !important;
    clip-path: polygon(var(--d) 0, calc(100% - var(--d)) 0, 100% var(--d), 100% calc(100% - var(--d)), calc(100% - var(--d)) 100%, var(--d) 100%, 0 calc(100% - var(--d)), 0 var(--d));
    border-radius: 12px;

}

.button:hover {
    background-color: #DDB96E !important;
    color: #DDB96E !important;

}

.language {
    background-color: rgba(255, 255, 255, 0.09);
    backdrop-filter: blur(6.6px);
    /* Removed unit from the blur value */
    color: #262729;
    text-align: center;
    font-weight: bold;
    font-size: 21px;
    font-family: Quicksand;
    line-height: auto;
    border-style: hidden;
    outline: none;
    width: 90px;
    height: 100%;
    --d: 9px !important;
    --b: 4px !important;
    --c: orange !important;
    --gc: var(--c) calc(50% + var(--b) * 0.707), #0000 0 !important;
    --gl: var(--c), #0000 40% 60%, var(--c) !important;
    clip-path: polygon(var(--d) 0, calc(100% - var(--d)) 0, 100% var(--d), 100% calc(100% - var(--d)), calc(100% - var(--d)) 100%, var(--d) 100%, 0 calc(100% - var(--d)), 0 var(--d));
    border-radius: 12px;
    padding: 9px;
    gap: .8rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

}

.buttonContainer .button {
    position: relative;
    width: 166px !important;
    height: 100%;
    background-color: #DDB96E;
    overflow: hidden;
}



.buttonContainer .button:hover::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: black;
    --d: 8.5px !important;
    --b: 4px !important;
    --c: orange !important;
    --gc: var(--c) calc(50% + var(--b) * 0.707), #0000 0 !important;
    --gl: var(--c), #0000 40% 60%, var(--c) !important;
    clip-path: polygon(var(--d) 0, calc(100% - var(--d)) 0, 100% var(--d), 100% calc(100% - var(--d)), calc(100% - var(--d)) 100%, var(--d) 100%, 0 calc(100% - var(--d)), 0 var(--d));
    border-radius: 12px;
    transform: scale(0.98, 0.94);
    z-index: -1;
    height: 100%;
}

.logoContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 10%;
}

.dot {
    justify-content: center;
    display: flex;
}

.dot svg {
    width: 11px;
    height: 11px;
}

.navLink {
    color: #AAACAF !important;
}

.active {
    color: #FFFFFF !important;
}

.navItem a {
    padding-bottom: 0px;
}



.mobileNavBar {
    position: fixed;
    z-index: 9999;
    background: rgba(15, 15, 15, 0.80);
    backdrop-filter: blur(2px);
    height: 15%;
    width: 100%;
    display: flex;
    align-items: center;
}

.TabletNavBar {
    position: fixed;
    z-index: 9999;
    background: rgba(15, 15, 15, 0.80);
    backdrop-filter: blur(2px);
    height: 15%;
    width: 100%;
    display: flex;
    align-items: center;
}

.mobBavButton {
    background-color: #DDB96E;
    color: #262729;
    text-align: center;
    font-weight: bold;
    font-size: 21px;
    font-family: Quicksand;
    line-height: auto;
    border-style: hidden;
    outline: none;
    width: 7.5rem;
    --d: 8.5px !important;
    --b: 6px !important;
    --c: orange !important;
    --gc: var(--c) calc(50% + var(--b) * 0.707), #0000 0 !important;
    --gl: var(--c), #0000 40% 60%, var(--c) !important;
    clip-path: polygon(var(--d) 0, calc(100% - var(--d)) 0, 100% var(--d), 100% calc(100% - var(--d)), calc(100% - var(--d)) 100%, var(--d) 100%, 0 calc(100% - var(--d)), 0 var(--d));
    border-radius: 12px;
    z-index: 99 !important;
    transform: scale(1.1, 1.1);
}

.mobButtonCont {
    height: 3.1rem;
    background-color: #DDB96E;
    z-index: 99;
    --d: 8.5px !important;
    --b: 6px !important;
    --c: orange !important;
    --gc: var(--c) calc(50% + var(--b) * 0.707), #0000 0 !important;
    --gl: var(--c), #0000 40% 60%, var(--c) !important;
    clip-path: polygon(var(--d) 0, calc(100% - var(--d)) 0, 100% var(--d), 100% calc(100% - var(--d)), calc(100% - var(--d)) 100%, var(--d) 100%, 0 calc(100% - var(--d)), 0 var(--d));
    border-radius: 12px;
}

.mobButtonCont .mobBavButton {
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: black;
    --d: 8.5px !important;
    --b: 4px !important;
    --c: orange !important;
    --gc: var(--c) calc(50% + var(--b) * 0.707), #0000 0 !important;
    --gl: var(--c), #0000 40% 60%, var(--c) !important;
    clip-path: polygon(var(--d) 0, calc(100% - var(--d)) 0, 100% var(--d), 100% calc(100% - var(--d)), calc(100% - var(--d)) 100%, var(--d) 100%, 0 calc(100% - var(--d)), 0 var(--d));
    transform: scale(0.986, 0.97);
    padding: 0;
}

.mobButtonCont .mobBavButton {
    position: relative;
    color: var(--Sand-100, #E4C78B);
    text-align: center;
    font-family: Quicksand;
    font-size: 0.85rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    overflow: hidden;
}

.mobileNavBar {
    display: none !important;
}



.TabletNavBar {
    display: none !important;
}

@media(max-width:1024px) {
    .mobileNavBar {
        display: none !important;
    }

    .desktopNavBar {
        display: none !important;
    }

    .TabletNavBar {
        display: flex !important;
    }

    .buttonContainer .button {
        position: relative;
        width: 120px !important;
        height: 35px !important;
        background-color: #DDB96E;
        overflow: hidden;
    }

    .button {
        font-size: 14px;
    }

    .mobButtonCont {
        width: 120px !important;
        height: 2.5rem;
        margin-left: 7px;
    }
}

@media (max-width: 600px) {

    /* Styles for devices with a maximum width of 600px (mobile devices) */
    .mobileNavBar {
        display: flex !important;
    }

    .desktopNavBar {
        display: none !important;
    }

    .TabletNavBar {
        display: none !important;
    }
}


.transitionOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: black;
    /* Change to desired color */
    opacity: 0.7;
    /* Adjust opacity as needed */
    z-index: 999;
    /* Ensure it's above other content */
    animation: slide 1s ease;
    /* Adjust animation duration and easing */
}

@keyframes slide {
    0% {
        transform: translateX(-100%);
        /* Start from left */
    }

    100% {
        transform: translateX(0);
        /* End at center */
    }
}

.arrows {
    z-index: 99;
    position: absolute;
    top: 37vh;
    left: 50px;
}

.arrows22 {
    z-index: 99;
    position: absolute;
    top: 37vh;
    right: 50px;
}

.TopArrow {
    background-image: url('/public/leftarrow.svg');
    background-size: cover;
    transform: rotate(90deg);
    height: 38px;
    width: 38px;
    background-color: transparent;
    border: none;
}

.bottomArrow {
    background-image: url('/public/rightarrow.svg');
    transform: rotate(90deg);
    height: 38px;
    width: 38px;
    background-color: transparent;
    border: none;
    background-size: cover;
}

.arrowdots {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    height: 130px;

}

.arrowdot {
    cursor: pointer;
    margin: 0 5px;
    font-size: 20px;
    border-radius: 2px;
    width: 9px;
    height: 8px;
    background-color: rgba(255, 255, 255, 0.20);
}

.active-dot {
    background-color: #EBD5A8;
    height: 44px !important;
    width: 9px;
    border-radius: 2px;
}

@media(max-width:1024px) {
    .arrows {
        display: none;
    }
}

@media(max-width:1024px) {
    .arrows22 {
        display: none;
    }
}

/* dropdown styling */
.dropdownMenu {
    display: flex;
    padding: 12px;
    flex-direction: column;
    align-items: flex-start;
    gap: 14px;
    position: absolute;
    left: var(--menu-left, 38%);
    top: var(--menu-top, 150px);
    transform: translate(-50%, -50%);
    border-radius: 4px;
    background: var(--Black-300, #262729);
    z-index: 999;

    &::before {
        content: '';
        position: absolute;
        width: 0;
        height: 0;
        border-left: 10px solid transparent;
        /* يمكن تعديل الحجم واللون حسب الحاجة */
        border-right: 10px solid transparent;
        border-bottom: 10px solid #262729;
        /* يمكن تعديل اللون هنا ليتطابق مع الخلفية */
        top: -10px;
        /* يمكن ضبط هذا الرقم للتحكم في ارتفاع المثلث فوق القائمة */
        left: 50%;
        transform: translateX(-50%);
        z-index: 2;
        /* ضمان عرض المثلث فوق القائمة */
    }

}


.dropdownMenu li {
    color: #C3C3C3;
    font-family: Quicksand;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    list-style-type: none;
    padding-bottom: 12px;
}