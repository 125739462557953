 .Card {
     display: flex;
     width: 13.875rem;
     height: 18rem;
     padding: 1.5rem;
     flex-direction: column;
     align-items: center;
     gap: 1.375rem;
     border-radius: 0.375rem;
     background: var(--Black-500, #171719);
     margin-left: 1.5rem;
 }

 .cardLogo {
     width: 9.64125rem;
     height: 9.779rem;
 }

 .cardDownImgs {
     display: flex;
     flex-direction: row;


 }

 .cardFrame1 {
     position: absolute;
     padding-left: 2.3rem;
     z-index: 1;
 }

 .cardFrame2 {
     position: absolute;
     margin-left: 4.55rem;
     z-index: 3;
 }

 .cardImg1 {
     display: inline-flex;
     height: 2.75413rem;
     z-index: 0;
     justify-content: flex-end;
     align-items: center;
     flex-shrink: 0;
     border-radius: 0.27406rem;
 }

 .cardImg2 {
     display: inline-flex;
     height: 2.75413rem;

     justify-content: flex-end;
     align-items: center;
     flex-shrink: 0;
     border-radius: 0.27406rem;
     position: fixed;
     z-index: 2;
     margin-left: 2.6rem;
 }

 .cardImg3 {
     display: inline-flex;
     height: 2.75413rem;
     z-index: 4;

     justify-content: flex-end;
     align-items: center;
     flex-shrink: 0;
     border-radius: 0.27406rem;
 }

 .cardContainer {
     display: flex;
     flex-direction: row;
     gap: 10px;

 }

 .mainTitle {
     font-family: Quicksand;
     font-size: 2.25rem;
     font-style: normal;
     font-weight: 400;
     margin-left: 2rem;

 }

 .modaloverlay {
     padding-top: 6%;
     z-index: 1031;
     position: fixed;
     top: 0;
     left: 0;
     width: 100%;
     height: 100%;
     background: rgba(0, 0, 0, 0.884);
     display: flex;
     justify-content: center;
     align-items: center;
 }

 .modalmob {

     padding-left: 5%;
     padding-right: 5%;
     border-radius: 5px;
     padding-bottom: 2%;
     border: .1px solid var(--Sand-100, #f2f7b8);
     background: var(--Black-1000, #000);
     display: flex;
     width: 85%;
     height: 70%;
     flex-direction: column;
     position: absolute;
     align-items: center;
     gap: 2%;
     flex-shrink: 0;
     z-index: 999;
     height: 80vw;
     width: 85%;
     justify-content: center;
 }

 .logoContainer {
     background-image: url('/public/Partners/logoBG.svg');
     /* Adjust the path to your logobg.svg */
     background-size: contain;
     /* Ensures the background image fits within the container */
     background-repeat: no-repeat;
     /* Prevents repeating the background */
     background-position: center;
     /* Centers the background image */
     width: 35vw;
     /* Width of your logo image */
     height: 35vw;
     /* Height of your logo image */
     display: flex;
     justify-content: center;
     align-items: center;
     position: relative;
     /* Ensures z-index works */
 }

 .logoImage {
     width: 55%;
 }

 .namePartner {
     font-family: Quicksand;
     font-size: 1rem;
     font-style: normal;
     font-weight: 400;
     color: wheat;

 }