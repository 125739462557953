.socialButtons {
  position: fixed;
  right: 3rem;
  top: 36vh;
  display: flex;
  flex-direction: column;
  z-index: 99;
}

.socialButtons a {
  display: inline-flex;
  text-decoration: none;
  font-size: 32px;

  color: #fff;
  justify-content: center;
  align-items: center;
  position: relative;
  margin: 4px 0 20px 0;
}

.socialButtons a::before {
  content: "";
  position: absolute;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  z-index: -1;
  transition: 0.3s ease-in;
}

.socialButtons a:hover::before {
  transform: scale(0);
}

.socialButtons a i {
  transition: 0.3s ease-in;
  /* Add your transition properties here */
}

@media (max-width: 1024px) {
  /* Styles for devices with a maximum width of 600px (mobile devices) */

  .socialButtons {
    display: none !important;
  }
}



.socialButtons2 {
  position: fixed;
  left: 3rem;
  top: 36vh;
  display: flex;
  flex-direction: column;
  z-index: 99;
}

.socialButtons2 a {
  display: inline-flex;
  text-decoration: none;
  font-size: 32px;

  color: #fff;
  justify-content: center;
  align-items: center;
  position: relative;
  margin: 4px 0 20px 0;
}

.socialButtons2 a::before {
  content: "";
  position: absolute;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  z-index: -1;
  transition: 0.3s ease-in;
}

.socialButtons2 a:hover::before {
  transform: scale(0);
}

.socialButtons2 a i {
  transition: 0.3s ease-in;
  /* Add your transition properties here */
}

@media (max-width: 1024px) {
  /* Styles for devices with a maximum width of 600px (mobile devices) */

  .socialButtons2 {
    display: none !important;
  }
}