.servicesbg {
    bottom: 0;
    z-index: 0;
    height: 100vh;
    width: 100%;
    background-image: url('/public/fsbg.png');
    background-size: cover;
    background-position: center right;
    background-repeat: no-repeat;
    /* filter: brightness(0.5); */
}


.Sectorbg {
    position: fixed;
    bottom: 0;
    z-index: 0;
    height: 100vh;
    width: 100%;
    background-image: url('/public/sectors.png');
    background-size: 100%;
    background-position: center center;
    background-repeat: no-repeat;
    /* filter: brightness(0.5); */

}



.mainTitleWhite {
    color: #ffffff;
    font-family: Quicksand;
    font-size: 6rem;
    font-style: normal;
    font-weight: 400;
    line-height: 90%;
}

.mainTitleYellow {
    color: #EBD5A8;
    font-family: Quicksand;
    font-size: 6rem;
    font-style: normal;
    font-weight: 400;
    line-height: 90%;

}

.mainTitleWhite2 {
    color: #ffffff;
    text-align: right;
    font-family: Cairo Light;
    font-size: 5rem;
    font-style: normal;
    font-weight: 400;
    line-height: 90%;
}

.mainTitleYellow2 {
    color: #EBD5A8;
    text-align: right;
    font-family: Cairo Light;
    font-size: 5rem;
    font-style: normal;
    font-weight: 400;
    line-height: 90%;

}

.emptyBox {
    height: 33vh;
    width: 33vh;
}

.serBox {
    height: 33vh;
    width: 33vh;
    flex-shrink: 0;

    box-shadow: 8px 8px 4px #00000040;
    background-color: rgba(23, 23, 25, 1);
    --d: 15px;
    --b: 4px;
    --c: orange;
    --gc: var(--c) calc(50% + var(--b)*0.707), #0000 0;
    --gl: var(--c), #0000 40% 60%, var(--c);
    clip-path: polygon(var(--d) 0, calc(100% - var(--d)) 0, 100% var(--d), 100% calc(100% - var(--d)), calc(100% - var(--d)) 100%, var(--d) 100%, 0 calc(100% - var(--d)), 0 var(--d));
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 35px;
    padding-right: 35px;
    flex-direction: column;
}

.serBox:hover {
    background-color: rgba(0, 0, 0, 0.2);
    box-shadow: 8px 8px 4px 0px rgba(0, 0, 0, 0.25);
}

.serBox2 {
    height: 33vh;
    width: 33vh;
    flex-shrink: 0;

    box-shadow: 8px 8px 4px #00000040;
    background-color: rgba(23, 23, 25, 1);
    --d: 15px;
    --b: 4px;
    --c: orange;
    --gc: var(--c) calc(50% + var(--b)*0.707), #0000 0;
    --gl: var(--c), #0000 40% 60%, var(--c);
    clip-path: polygon(var(--d) 0, calc(100% - var(--d)) 0, 100% var(--d), 100% calc(100% - var(--d)), calc(100% - var(--d)) 100%, var(--d) 100%, 0 calc(100% - var(--d)), 0 var(--d));
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 35px;
    padding-right: 35px;
    flex-direction: column;
}

.serBox2:hover {
    background-color: rgba(0, 0, 0, 0.2);
    box-shadow: 8px 8px 4px 0px rgba(0, 0, 0, 0.25);
}

.FinancialServices {
    position: relative;
    transition: transform 1s ease-out;
    transform: translateX(100%);

}

.FinancialServices.animate {
    transform: translateX(0);
    /* Move to the center */
}

.overlay {
    position: absolute;
    top: 0px;
    left: 0;
    width: 100%;
    height: 120vh;
    background: linear-gradient(to bottom, #000000, #53535380);
    display: flex;
    flex-direction: column;
    align-items: center;

    color: #fff;
    z-index: 1;

}


.boxContent {
    width: 60%;
    margin-left: 30px;

}

.serBox:hover svg path,
.serBox:hover h5,
.serBox:hover {
    stroke: #DDB96E;
    color: #DDB96E !important;
    cursor: pointer;
}

.serBox2:hover svg path,
.serBox2:hover h5,
.serBox2:hover {
    stroke: #DDB96E;
    color: #DDB96E !important;
    cursor: pointer;
}

.boxTitle h5 {
    color: #FFF !important;
    font-family: Quicksand;
    font-size: 1.4rem !important;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-top: 16px !important;
}

.boxTitle2 h5 {
    color: #FFF !important;
    text-align: right;
    font-family: Cairo Light;
    font-size: 1.4rem !important;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-top: 16px !important;
    margin-right: 0;
}

.NumWhite {
    color: var(--White-100, #FFF);
    font-family: Quicksand;
    font-size: 3rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.NumWhite2 {
    color: var(--White-100, #FFF);
    text-align: right;

    font-family: Cairo Light;
    font-size: 3rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.NumYellow {
    color: var(--White-600, #EBD5A8);
    font-family: Quicksand;
    font-size: 4rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.NumYellow2 {
    color: var(--White-600, #EBD5A8);
    font-family: Quicksand;
    font-size: 4rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    justify-content: center;
    align-items: center;
}

.section2 {
    width: 100%;
    height: 100%;
    margin-top: 10%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 3%;
}

.section {
    /* margin: 0 60px 0 60px;
    width: 90%; */
    width: 100%;
    margin-left: 8%;
    margin-right: 8%;
}

/* @media (max-width:1360px) {

    .mainTitleWhite,
    .mainTitleYellow {
        font-size: 7rem;
        display: inline;

    }

    .section {
        width: 100%;
    }

    .servicesbg {

        overflow-y: auto;
    }
} */

/* 
@media (max-width: 1024px) {
    .servicesbg {
        height: 100%;
        overflow-y: auto;
    }

    .serBox {
        width: auto;
        height: 21vh;
    }

    .mainTitleWhite,
    .mainTitleYellow {
        font-size: 5rem;
        display: inline;
    }

    h5 {
        font-size: 1.2rem;
    }

    .section {
        width: 100%;
        margin: 150px 20px 0 20px;
    }

    .section2 {
        margin-top: 150px !important;
    }

    svg {
        width: 50px;
        height: 50px;
    }

    body {
        overflow-y: auto;
    }

}

@media (max-width: 760px) {
    .servicesbg {
        background-image: none;
        overflow-y: auto;
    }

    body {
        overflow-y: auto;
    }


    .section {
        width: 100%;
        padding: 5px;

        margin: 40px 20px 0 20px;

    }

    .container {
        padding: 0 !important;
        max-width: 100% !important;
    }

    .serBox {
        width: auto;
        margin-right: 0;
        margin-bottom: 20px;
        height: 150px;
        padding: 20px;
    }

    .mainTitleWhite,
    .mainTitleYellow {
        font-size: 2rem;
        display: inline;
    }

    h5 {
        font-size: 0.8rem !important;
    }


    svg {
        width: 40px;
        height: 40px;
    }

    .overlay {
        position: relative;
    }

    .NumWhite,
    .NumYellow {
        font-size: 1.5rem;
    }

    .section2 {
        margin-top: 40px !important;
    }

} */

.ContainerPage {
    display: flex;
    flex-direction: column;
    width: 80%;
    gap: 5vh;
    margin-left: 14%;
    margin-right: 7%;
    margin-top: 10.5%;
}

.firstRow {
    display: flex;
    flex-direction: row;
    height: 45%;
    gap: 5%;
    width: 70%;
}

.secondRow {
    display: flex;
    flex-direction: row;
    height: 45%;
    gap: 5%;
    width: 67%;
}

.topCards {
    display: flex;
    flex-direction: row;
    gap: 10%;
    width: 50%;
}

.secondRowfirstCell {
    display: flex;
    flex-direction: row;
    justify-content: end;
    width: 48.5%;

}

.rightCont {
    display: flex;
    flex-direction: column;
    gap: 5%;
}

.leftCont {
    display: flex;
    flex-direction: column;
    gap: 5%;
}

.rightBottomCont {
    display: flex;
    flex-direction: row;

}

.rightTopCont {
    display: flex;
    flex-direction: row;
    gap: 5%;
}

.rightBottomCont {
    display: flex;
    flex-direction: row;
    gap: 5%;
}

.textContent {
    width: 100%;
}