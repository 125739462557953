/* Variables */
:root {
  --col-bg: #fff;
  --col-main: #F1E3C5;
  --border-sz: 0.1rem;
}

/* Reset and Base Styles */
 

h1 {
  margin: 2.5rem 0 1.5rem 0;
}

a {
  text-decoration: none !important;
  color: #F8F1E2 !important;
  transition: all 0.3s ease;
  cursor: pointer;
}

a:hover {
  color: var(--col-main);
  
}

button {
  font: inherit;
  font-size: 1.4rem;
  font-weight: normal;
  background: transparent;
  border: 0;
  outline: 0;
  cursor: pointer;
}

button.btn-main {
  color: var(--col-bg);


}

input {
  font: inherit;
  font-weight: normal;
  border: var(--border-sz) solid rgba(0, 0, 0, 0.1);
  outline: 0;
}

input::placeholder {
  color: rgba(0, 0, 0, 0.4);
}

.input-main {
  border: var(--border-sz) solid var(--col-main);
  
}

input,
button {
  height: 2.4rem;
  padding: 0.5rem 1rem;
  line-height: 1;
}

.input-group {
  display: flex;
  align-items: center;
  justify-content: center;
}

.input-group input {
  width: 80%;
}

.input-group button {
  width: 20%;
}

#app {
  width: 21rem;
}

.search {
  width: 100%;
  margin: 1rem 0;
}

/* Calendar Styles */
.month {
  margin: 0;
  font-size: .9rem !important;
}
.month-active {
align-items: center;
color: var(--Gray-50, #B9BBBE);
}
.month .month-active {
  width: 9rem;
}
.month-selector{
  display: flex !important;
    flex-direction: row;
  justify-content: center;
}
.month-selector a{
  display: flex !important;
  
  justify-content: center;
}
.month-selector span{
  display: flex !important;
  
  justify-content: center;
}

.month .month-selector a {
  display: inline-block;
  width: 3rem;
  height: 3rem;
  line-height: 3rem;
}

.month .month-active,
.month .month-selector {
  display: inline-block;
 
}


.weekdays{
  border-top-left-radius: 15px;
border-top-right-radius: 15px;

}

.days{
  border-bottom-left-radius: 15px;
border-bottom-right-radius: 15px;
}
 
.weekdays .day,
.days .day {
  width: 3rem;
  height: 3rem;
  line-height: 3rem;
  color: var(--Gray-50, #B9BBBE);
}

 

.days .day {
  position: relative;
  cursor: pointer;
 
  display: flex;
  justify-content: center;

  color: var(--Gray-50, #B9BBBE);
 
font-family: Quicksand;
 
 

}

.days .day:not(.day-empty):not(.day-active):not(.has-matches):hover {
  color: #DDB96E;
   border-radius: 50%;
 
}
  .day-active{
    color: var(--Black-800, #080808) !important;
    
    font-family: Quicksand;
  
}
.days .day.day-active {
  color: #fff;
  background: var(--col-main);
  width: 25px;
  height: 25px;
  border-radius: 15px;
  align-items: center;
  
}

.days .day.day-active.has-events::before {
  background: var(--col-bg);
}
.day{
  display: flex !important;
  justify-content: center !important;

}
.days .day.has-events::before {
  content: '';
  position: absolute;
  display: block;
  top: 0.4rem;
  left: 0.4rem;
  width: 0.35rem;
  height: 0.35rem;
  border-radius: 50%;
  background: var(--col-main);
  
  
}

.days .day.has-matches:not(.day-active) {
  background: rgba(255, 0, 0, 0.1);
  color: var(--col-main);
    
  
}

.events {
  border-bottom: var(--border-sz) solid rgba(0, 0, 0, 0.1);
}

.events ul {
  margin: 0 0 1.5rem 1.5rem;
  padding: 0;
}

.events ul li {
  list-style-type: disc;
  word-break: break-word;
  text-align: left;
  padding: 0;
  font-size: .5rem;
}

.events ul li a {
  padding: 0 0.5rem;
  line-height: 2rem;
  font-weight: bold;
  transition: 0.3s all ease;
  opacity: 0;
}

.events ul li a:hover {
  opacity: 1;
}

.events p {
  margin: 0 0 2rem 0;
}

.event-add {
  margin: 2rem 0 4rem 0;
}

/* Responsive Styles */
@media only screen and (max-width: 32rem) {
  #app {
    width: 95%;
  }

  .calendar {
    width: 100%;
  }

  .calendar .days,
  .calendar .weekdays {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .calendar .day {
    font-size: .9rem; /* Adjust font size as needed */
    width: 2.1rem; /* Set a fixed width for each day */
    height: 3rem; /* Set a fixed height for each day */
    line-height: 3rem;
    flex: 0 0 auto; /* Prevents flex items from growing or shrinking */
    box-sizing: border-box; /* Ensure padding and border are included in the width */
    text-align: center; /* Center-align text */
  }

  .calendar .day.has-events::before {
    top: 0.6rem; /* Adjust position as needed */
    left: 0.6rem; /* Adjust position as needed */
    width: 0.8rem; /* Adjust size as needed */
    height: 0.8rem; /* Adjust size as needed */
  }
}

.separator{
  width: 90%;
height: 1.048px;
 
left: 45.208px;
top: 85.075px;
background-color: white;
}
@media(max-width:600px){
.weekdays,
.days {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  user-select: none;
  justify-content: center;
  align-items: center;
  width: 16rem !important;
 }
 .day-container{
  width: 2.1rem;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
}
}
@media(min-width:600px){
  .weekdays,
  .days {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    user-select: none;
    justify-content: center;
    align-items: center;
    width:21rem !important;
   }
   .day-container{
    width: 3rem;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  }